import { ref, reactive } from 'vue';
import { useToast } from 'primevue/usetoast';

export function useUser() {
  //HOOKS
    const supabase = useSupabaseClient()
    const user = useSupabaseUser()

//METHODS
    const checkUserAcces = async () => {
            const userEmail = user.value?.email as string
            

            const { data, error } = await supabase
            .from('subs')
            .select()
            .eq('userEmail', userEmail)
            .single()
            
            if(data?.isSubscribed == true){
                   return true
            }

            if(!data || data === null || data?.isSubscribed == false) {
                  return false
            }
            
            if(error) {
                console.error(error);
            }


            return false
    }


    return {
        checkUserAcces,
    };
}
