export default defineNuxtRouteMiddleware(async (to, from) => {
  const user = useSupabaseUser()
  const supabase = useSupabaseClient()
  const {checkUserAcces} = useUser()
  const propertyStore = userPropertyStore()
  const {getProperty} = userPropertyStore()
  const {property} = storeToRefs(propertyStore)



  // Check if the user is navigating to a /pms route
  if (to.path.includes('/pms')) {
    const check = await checkUserAcces();

    // Fetch the property data
    await getProperty()

    if(!property.value.id){
      return navigateTo('/registration', {redirectCode:301})
    }

    // Redirect to Stripe if access is not allowed
    if (!check) {
      return navigateTo(`https://buy.stripe.com/28o2aA0GMeOhftK144?prefilled_email=${user.value?.email}`, { redirectCode: 301, external: true }) //BASIC-PLAN

    }
  }
  
  });